<template>
  <div :id="id" class="mts-form-wrapper" :class="{ '--with-info': showInfo }">
    <div v-if="image" class="mts-form-wrapper__image">
      <picture>
        <template v-if="image.srcMobile">
          <source
            :srcset="image.srcMobileWebp"
            type="image/webp"
            media="(max-width: 767px)"
          />
          <source
            :srcset="image.srcMobile"
            :type="imageType"
            media="(max-width: 767px)"
          />
        </template>
        <template v-if="image.srcTablet">
          <source
            :srcset="image.srcTabletWebp"
            type="image/webp"
            media="(max-width: 991px)"
          />
          <source
            :srcset="image.srcTablet"
            :type="imageType"
            media="(max-width: 991px)"
          />
        </template>
        <source
          v-if="image.srcWebp"
          :srcset="image.srcWebp"
          type="image/webp"
        />
        <img v-if="image.src" :src="image.src" :alt="image.alt" />
      </picture>

      <div v-if="showInfo" class="info">
        <slot name="info">
          <div class="info__shield">Акция</div>
          <div class="info__title">
            Облачное решение на&nbsp;24&nbsp;месяца со&nbsp;скидкой 30%
          </div>
          <div class="info__text">
            Оставьте ваши контакты и&nbsp;получите специальное предложение
            для&nbsp;организаций здравоохранения
          </div>
        </slot>
      </div>
    </div>

    <div class="mts-form-wrapper__form">
      <div class="mts-form-wrapper__form-inner">
        <slot name="form" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MTSFormWrapper',
  props: {
    id: {
      type: String,
      default: 'form',
    },
    image: {
      type: Object,
    },
    imageType: {
      type: String,
      default: 'image/jpg',
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.mts-form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  padding: 64px 0;

  @include screen-down('lg') {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 40px 0;
  }

  @include screen-down('md') {
    padding: 32px 0;
  }

  &.--with-info {
    @include screen-down('lg') {
      grid-template-columns: 1fr;
      row-gap: 32px;
      padding: 40px 0;
    }
  }

  &__image {
    position: relative;
    border-radius: 24px;
    overflow: hidden;

    @include screen-down('lg') {
      display: none;
    }

    .--with-info & {
      @include screen-down('lg') {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    padding: 32px;
    border-radius: 24px;
    height: 100%;
    max-width: unset;

    @include screen-down('md') {
      padding: 20px;
    }

    &-inner {
      max-width: 388px;
      margin: 0 auto;
    }
  }
}

.info {
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
  border-radius: 24px;
  padding: 32px;
  max-width: 493px;

  @include screen-down('lg') {
    max-width: 441px;
    padding: 24px;
  }

  @include screen-down('md') {
    bottom: 20px;
    left: 20px;
    right: 20px;
    padding: 20px;
    max-width: 295px;
  }

  &__shield {
    padding: 10px 12px;
    border-radius: 12px;
    font-family: $font-mts-wide;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #fafafa;
    width: fit-content;
    background-color: #302c33;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  &__title {
    font-family: $font-mts-wide;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;

    @include screen-down('md') {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;

    @include screen-down('md') {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
</style>
